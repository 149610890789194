import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';

interface ViteManifest {
    [key: string]: {
        file: string;
        src?: string;
        isEntry?: boolean;
        css?: string[];
        imports?: string[];
    };
}

export function Prefetch() {
    const { enableAssetPrefetching } = useFlags();

    useEffect(() => {
        // This is not working properly on Android
        const isAndroid = () => /Android/i.test(navigator.userAgent);

        const loadManifest = async () => {
            try {
                const response = await fetch('/static/manifest.json?t=' + Date.now());
                const manifest: ViteManifest = await response.json();

                // Process manifest entries
                Object.values(manifest).forEach((entry) => {
                    if (entry.file.endsWith('.js')) {
                        // Create modulepreload for JS files
                        const link = document.createElement('link');
                        link.rel = 'modulepreload';
                        link.href = `/${entry.file}`;
                        link.as = 'script';
                        link.crossOrigin = 'anonymous';
                        link.onerror = () => {
                            console.error('Failed to load module', entry.file);
                        };
                        document.head.appendChild(link);
                    } else {
                        // Create preload for other assets
                        const link = document.createElement('link');
                        link.rel = 'preload';
                        link.crossOrigin = 'anonymous';
                        link.href = `/${entry.file}`;
                        link.onerror = () => {
                            console.error('Failed to load asset', entry.file);
                        };

                        // Set appropriate as attribute based on file type
                        if (entry.file.endsWith('.css')) {
                            link.as = 'style';
                        } else if (entry.file.match(/\.(woff2?|ttf|otf)$/i)) {
                            link.as = 'font';
                        } else if (entry.file.match(/\.(png|jpg|jpeg|gif|webp|svg)$/i)) {
                            link.as = 'image';
                        } else if (entry.file.match(/\.(mp4|webm)$/i)) {
                            link.as = 'video';
                        } else if (entry.file.match(/\.(mp3|wav|ogg)$/i)) {
                            link.as = 'audio';
                        }

                        document.head.appendChild(link);
                    }
                });
            } catch (error) {
                console.error('Failed to load Vite manifest:', error);
            }
        };

        if (enableAssetPrefetching && !isAndroid() && import.meta.env.VITE_ENV !== 'development') {
            setTimeout(() => {
                loadManifest();
            }, 3000);
        }
    }, []);

    return null;
}
