import { UserRole } from '../types/global';

interface HasuraClaims {
    'x-hasura-allowed-roles': string[];
    'x-hasura-auth0-user-id': string;
    'x-hasura-default-role': string;
    'x-hasura-external-org-ids': string;
    'x-hasura-hp-ids': string;
    'x-hasura-is-payment-admin': 'TRUE' | 'FALSE';
    'x-hasura-is-super-admin': 'TRUE' | 'FALSE';
    'x-hasura-is-support-account': 'TRUE' | 'FALSE';
    'x-hasura-is-support-account-approved': 'TRUE' | 'FALSE';
    'x-hasura-org-id': string;
    'x-hasura-roles': string;
    'x-hasura-roles-pa': string;
    'x-hasura-user-id': string;
}

interface CuriumClaims {
    auth0UserId: string;
    handlingParties: number[];
    isPaymentAdmin: boolean;
    isSuperAdmin: boolean;
    isSupportAccount: boolean;
    isSupportAccountApproved: boolean;
    orgId: number;
    roles: UserRole[];
    userId: number;
    email: string;
    isFirstSsoLogin: boolean;
    oid?: string;
    canViewUnallocatedClaims: boolean;
}

export interface JwtClaims {
    'https://hasura.io/jwt/claims': HasuraClaims;
    'https://curium.app/claims': CuriumClaims;
    iss: string;
    sub: string;
    aud: string[];
    iat: number;
    exp: number;
    scope: string;
    azp: string;
}

export function decodeJwtToken(token: string | undefined): JwtClaims | undefined {
    if (!token) {
        return undefined;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''),
    );

    return JSON.parse(jsonPayload);
}

export function isTokenValid(
    decodedToken: JwtClaims,
    requiredRoles: Array<UserRole>,
    requiredOrgId: number,
) {
    return (
        decodedToken['https://curium.app/claims'].roles.some((r) => requiredRoles.includes(r)) &&
        decodedToken['https://curium.app/claims'].orgId === requiredOrgId
    );
}
