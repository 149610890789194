export const smsTemplates = {
    claim_summary_link: 'claim_summary_link',
};

export const emailTemplates = {
    claim_summary_link: 'claim_summary_link',
};

export enum UserRoleEnum {
    'user' = 'user',
    'org_admin' = 'org_admin',
    'supplier' = 'supplier',
}

/**
 * @deprecated Use USER_ROLE instead
 */
export const USER_ROLES = [
    UserRoleEnum.user,
    UserRoleEnum.org_admin,
    UserRoleEnum.supplier,
] as const;

export const currencyFormat = {
    withSymbol: '$0,0.00',
    withoutSymbol: '0,0.00',
};

export const unallocatedPhotoUrl =
    'https://s.gravatar.com/avatar/514614ce39a3e0ef7f882c91e6a4981d?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fu.png';
