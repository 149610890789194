import { gql } from '@/__generated__';
import { useMutation } from '@apollo/client';
import { UserEvent } from 'shared/types/userEvent';
import { logInDev } from '@/utils/general';

export function useUserEvent() {
    const [insertUserEvent] = useMutation(insertUserEventMutation);

    const insertEvent = async (event: UserEvent, details: string) => {
        try {
            await insertUserEvent({
                variables: { object: { event: event, details: details } },
            });
        } catch (e) {
            logInDev('Error inserting event', e);
        }
    };

    return { insertEvent };
}

const insertUserEventMutation = gql(/* GraphQL */ `
    mutation insertUserEventMutation($object: EventInsertInput = {}) {
        insertEventOne(object: $object) {
            id
        }
    }
`);
