import { useOrgId } from '@/hooks/Org/useOrgId';
import { useEffect, useState } from 'react';
import { errorMessage } from '@/components/Common/errorMessage';
import { generatePath } from 'shared/navigation/utils';
import { navRoutes } from 'shared/navigation/navRoutes';
import { useIsPublicSection } from '@/hooks/useIsPublicSection';
import * as Sentry from '@sentry/react';

type ErrorFallbackProps = Parameters<Sentry.FallbackRender>[0];

export function ErrorFallback(props: ErrorFallbackProps) {
    const orgId = useOrgId();
    const [isErrorModalVisible, setIsErrorModalVisible] = useState(false);
    const isPublicSection = useIsPublicSection();

    useEffect(() => {
        // HMR will trigger this error fallback on every change, so disable it in development
        if (import.meta.env.VITE_ENV === 'development') {
            return;
        }
        const _ = async () => {
            if (props.error && !isErrorModalVisible) {
                try {
                    setIsErrorModalVisible(true);
                    await errorMessage.showAsync(props.error, { okText: 'Try again' });
                    props.resetError();
                    if (isPublicSection) {
                        location.href = generatePath(navRoutes.public_root, undefined);
                    } else {
                        location.href = generatePath(navRoutes.home, { orgId });
                    }
                } finally {
                    setIsErrorModalVisible(false);
                }
            }
        };
        void _();
    }, [props.error]);

    return <></>;
}
