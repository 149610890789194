import { useFlags } from 'launchdarkly-react-client-sdk';
import { notification, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import { navRoutes } from 'shared/navigation/navRoutes';
import { useNavigate } from '@/hooks/useNavigate';

export function MaintenanceModeOverlay({ children }: { children: ReactNode }) {
    const { inMaintenanceMode, forceDisableMaintenance } = useFlags();
    const navigate = useNavigate();
    const [location, setLocation] = useState<string | null>(null);

    useEffect(() => {
        Sentry.setTag('in-maintenance-mode', inMaintenanceMode ? true : false);
        if (forceDisableMaintenance) {
            return;
        }
        if (inMaintenanceMode) {
            setLocation(window.location.href);
            navigate({ route: navRoutes.public_root });
        } else {
            if (location) {
                notification.success({
                    message: 'Maintenance Complete',
                    placement: 'top',
                    description:
                        'The system maintenance is complete. Redirecting you to your previous location...',
                    duration: 3,
                    onClose: () => {
                        // Redirect to the previous location but add timestamp to the url to avoid caching.
                        window.location.href = `${location}${location.includes('?') ? '&' : '?'}t=${Date.now()}`;
                        setLocation(null);
                    },
                });
            }
        }
    }, [inMaintenanceMode, forceDisableMaintenance]);

    if (inMaintenanceMode && !forceDisableMaintenance) {
        return (
            <>
                <div className="flex h-screen w-full items-center justify-center bg-amber-300">
                    <div className="text-lg">
                        <Typography.Title level={2}>Under Maintenance</Typography.Title>
                        <Typography.Text>
                            The site is undergoing upgrade. We'll be back soon.
                        </Typography.Text>
                    </div>
                </div>
            </>
        );
    } else {
        return <>{children}</>;
    }
}
