import { MAIN_CONTENT_CONTAINER_ID } from '@/global/ui';
import { KeyboardEvent, MouseEvent } from 'react';
import {
    NavigateOptions as NavigateOptionsOriginal,
    PathParam,
    useNavigate as useNavigateOriginal,
} from 'react-router-dom';
import { IsNever } from 'type-fest';
import { generatePath } from 'shared/navigation/utils';
import { Route } from 'shared/navigation/navRoutes';

export type NavigateTo<R extends Route> =
    IsNever<PathParam<R['path']>> extends true
        ? {
              route: R;
              searchParams?: Partial<Record<keyof R['searchParams'], string | number | bigint>>;
              anchor?: R['anchors'];
          }
        : {
              route: R;
              params: Record<PathParam<R['path']>, string | number | bigint>;
              searchParams?: Partial<Record<keyof R['searchParams'], string | number>>;
              anchor?: R['anchors'];
          };

export type NavigateOptions<R extends Route> = Omit<NavigateOptionsOriginal, 'state'> & {
    state?: R['state'];
    target?: '_blank';
    scrollToTop?: boolean;
};

export const useNavigate = () => {
    const navigate = useNavigateOriginal();

    return <R extends Route>(
        to: NavigateTo<R>,
        event?: MouseEvent | KeyboardEvent,
        options?: NavigateOptions<R>,
    ) => {
        const path = generatePath(
            to.route,
            'params' in to ? to.params : (undefined as any),
            to.searchParams,
            to.anchor,
        );

        if (event?.ctrlKey || event?.metaKey || options?.target === '_blank') {
            window.open(path, '_blank');
            return;
        }

        if (options?.scrollToTop && !to.anchor) {
            const container = document.getElementById(MAIN_CONTENT_CONTAINER_ID);
            container?.scrollTo(0, 0);
        }

        navigate(path, options);

        if (to.anchor) {
            setTimeout(() => {
                const element = document.getElementById(to.anchor!);
                element?.scrollIntoView({ behavior: 'smooth' });
            });
        }
    };
};
