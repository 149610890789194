import { RequireAuth } from '@/components/Auth/RequireAuth';
import { ComponentLoading } from '@/components/Common/ComponentLoading';
import { ErrorFallback } from '@/components/Common/ErrorFallback';
import { errorMessage } from '@/components/Common/errorMessage';
import { modal } from '@/components/Common/modal';
import { OfflineOverlay } from '@/components/Common/OfflineOverlay';
import { DebugInfo } from '@/components/DebugInfo';
import { PublicLayout } from '@/components/Layout/PublicLayout';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import { useOfflineDetection } from '@/hooks/useOfflineDetection';
import { AdminAuthCallback } from '@/pages/Authentication/AdminAuthCallback';
import { AuthCallback } from '@/pages/Authentication/AuthCallback';
import { LandingPage } from '@/pages/LandingPage';
import { Logout } from '@/pages/Logout';
import { NotFound } from '@/pages/NotFound';
import loadable from '@loadable/component';
import { Modal } from 'antd';
import { Route, Routes } from 'react-router-dom';
import { MaintenanceModeOverlay } from './components/MaintenanceModeOverlay';
import * as Sentry from '@sentry/react';
import { LogOutIn } from './pages/LogOutIn';
import { Prefetch } from './components/Prefetch';
import { useAppBuildVersion } from './hooks/useAppBuildVersion';

const Console = loadable(() => import(/* webpackPrefetch: true */ '@/pages/Console'), {
    resolveComponent: (comps) => comps.Console,
    fallback: <ComponentLoading />,
});
const AdminConsole = loadable(() => import(/* webpackPrefetch: true */ '@/pages/AdminConsole'), {
    resolveComponent: (comps) => comps.AdminConsole,
    fallback: <ComponentLoading />,
});
const Public = loadable(() => import(/* webpackPrefetch: true */ '@/pages/Public'), {
    resolveComponent: (comps) => comps.Public,
    fallback: <ComponentLoading />,
});
const Login = loadable(() => import(/* webpackPrefetch: true */ '@/pages/Authentication/Login'), {
    resolveComponent: (comps) => comps.Login,
    fallback: <ComponentLoading isFullPage />,
});
const AdminLogin = loadable(
    () => import(/* webpackPrefetch: true */ '@/pages/Authentication/AdminLogin'),
    {
        resolveComponent: (comps) => comps.AdminLogin,
        fallback: <ComponentLoading isFullPage />,
    },
);
const AuthenticatedLayout = loadable(
    () => import(/* webpackPrefetch: true */ '@/components/Layout/AuthenticatedLayout'),
    {
        resolveComponent: (comps) => comps.AuthenticatedLayout,
    },
);

export function CuriumApp() {
    const [modalApi, contextHolder] = Modal.useModal();

    useOfflineDetection();
    useDocumentTitle();
    useAppBuildVersion();

    // We don't want to wait for useEffect as it will block the rendering
    errorMessage.init(modalApi);
    modal.init(modalApi);

    return (
        <>
            {contextHolder}

            <Sentry.ErrorBoundary fallback={ErrorFallback}>
                <OfflineOverlay />
                <MaintenanceModeOverlay>
                    <Routes>
                        <Route path="/" element={<LandingPage />} />
                        <Route path="login" element={<Login />} />
                        <Route path="logoutin" element={<LogOutIn />} />
                        <Route path="admin-login" element={<AdminLogin />} />
                        <Route path="callback" element={<AuthCallback />} />
                        <Route path="admin-callback" element={<AdminAuthCallback />} />
                        <Route path="logout" element={<Logout />} />
                        <Route
                            path="public/*"
                            element={
                                <PublicLayout>
                                    <Public />
                                </PublicLayout>
                            }
                        />
                        <Route
                            path="console/:orgId/*"
                            element={
                                <RequireAuth loginUrl="/login">
                                    <AuthenticatedLayout>
                                        <Console />
                                    </AuthenticatedLayout>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="admin-console/*"
                            element={
                                <RequireAuth loginUrl="/admin-login">
                                    <AuthenticatedLayout>
                                        <AdminConsole />
                                    </AuthenticatedLayout>
                                </RequireAuth>
                            }
                        />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                    <DebugInfo />
                </MaintenanceModeOverlay>
                <Prefetch />
            </Sentry.ErrorBoundary>
        </>
    );
}
