import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { isRoute, navRoutes } from 'shared/navigation/navRoutes';

export const useDocumentTitle = () => {
    const location = useLocation();
    const defaultSuffix = ' - Curium';

    useEffect(() => {
        const findRouteTitle = (): string => {
            let title = 'Curium';

            const findTitle = (routes: typeof navRoutes): boolean => {
                let key: keyof typeof navRoutes;
                for (key in routes) {
                    const route = routes[key];

                    if (isRoute(route)) {
                        if (!route.title) {
                            return true;
                        }

                        const match = matchPath(
                            { path: route.path, caseSensitive: false, end: true },
                            location.pathname,
                        );
                        if (match) {
                            title = `${route.title} ${match?.params?.id ? ` - ${match?.params?.id}` : ''} ${defaultSuffix}`;
                            return true;
                        }
                    }
                    // else {
                    // TODO: Recurse into nested routes
                    // findTitle(route as any);
                    // }
                }
                return false;
            };

            findTitle(navRoutes);
            return title;
        };

        document.title = findRouteTitle();
    }, [location]);
};
