import { useTrpcClient } from '@/hooks/useTrpcClient';
import { useAuthStore } from '@/stores/AuthStore';
import { useOrgSettingsStore } from '@/stores/OrgSettingStore';
import { trpc } from '@/trpc';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpBatchLink, unstable_httpBatchStreamLink } from '@trpc/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ReactNode, useEffect, useMemo, useRef } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import superjson from 'superjson';

const queryClient = new QueryClient();

export function TRpc({ children }: { children: ReactNode }) {
    const { enableTrpcBatchStreamLink } = useFlags();
    const getAuthToken = useAuthStore((state) => state.getAuthToken);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const refExecuteRecaptcha = useRef(executeRecaptcha);

    refExecuteRecaptcha.current = executeRecaptcha;

    const trpcClient = useMemo(() => {
        return trpc.createClient({
            links: [
                enableTrpcBatchStreamLink
                    ? unstable_httpBatchStreamLink({
                          url: (import.meta.env.VITE_API_URL || location.origin) + '/trpc',
                          transformer: superjson,
                          async headers(opts) {
                              const headers: Record<string, string> = {
                                  'x-page-url': window.location.href,
                              };
                              if (opts.opList.some((op) => op.context?.reCaptcha === true)) {
                                  if (!refExecuteRecaptcha.current) {
                                      throw new Error('executeRecaptcha is not defined');
                                  }
                                  headers['x-recaptcha-token'] =
                                      await refExecuteRecaptcha.current();
                              }
                              const token = getAuthToken();
                              if (token) {
                                  headers['authorization'] = `Bearer ${token}`;
                              }
                              return headers;
                          },
                      })
                    : httpBatchLink({
                          url: (import.meta.env.VITE_API_URL || location.origin) + '/trpc',
                          transformer: superjson,
                          async headers(opts) {
                              const headers: Record<string, string> = {
                                  'x-page-url': window.location.href,
                              };
                              if (opts.opList.some((op) => op.context?.reCaptcha === true)) {
                                  if (!refExecuteRecaptcha.current) {
                                      throw new Error('executeRecaptcha is not defined');
                                  }
                                  headers['x-recaptcha-token'] =
                                      await refExecuteRecaptcha.current();
                              }
                              const token = getAuthToken();
                              if (token) {
                                  headers['authorization'] = `Bearer ${token}`;
                              }
                              return headers;
                          },
                      }),
            ],
        });
    }, [enableTrpcBatchStreamLink, getAuthToken, refExecuteRecaptcha]);

    return (
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>
                <SetTrpcClient>{children}</SetTrpcClient>
            </QueryClientProvider>
        </trpc.Provider>
    );
}

const SetTrpcClient = ({ children }: { children: ReactNode }) => {
    const authStore = useAuthStore.getState();
    const orgSettingsStore = useOrgSettingsStore.getState();
    const trpcClient = useTrpcClient();

    useEffect(() => {
        if (trpcClient) {
            Sentry.addBreadcrumb({
                category: 'trpc',
                message: 'trpcClient set in SetTrpcClient',
            });
            authStore.setTrpcClient(trpcClient);
            orgSettingsStore.setTrpcClient(trpcClient);
        }
    }, [trpcClient]);

    if (!trpcClient) {
        return null;
    }

    return <>{children}</>;
};
