import { useCurrentOrg } from '@/hooks/Org/useCurrentOrg';
import { useCurrentUser } from '@/hooks/User/useCurrentUser';
import { cloneDeep } from '@/utils/general';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { ProductFruits } from 'react-product-fruits';
import { SYSTEM_ORG_ID } from 'shared/constants/org';

export const ProductFruitsSetup = observer(() => {
    const user = useCurrentUser();
    const org = useCurrentOrg();

    const productFruitsUser = {
        username: user.fullName,
        email: user.email!,
        firstname: user.firstName,
        lastname: user.lastName,
        signUpAt: user.signedUpAt?.toISOString(),
        props: {
            orgId: org.id,
            userRoles: cloneDeep(user.roles),
            claimModuleEnabled: org.isClaimFeatureEnabled,
            complianceModuleEnabled: org.isComplianceFeatureEnabled,
            riskModuleEnabled: org.isRiskFeatureEnabled,
            isEmployee: user.isEmployee,
        },
    };

    useEffect(() => {
        //@ts-expect-error - window.productFruits is not typed
        window.productFruits?.identifyUser?.(productFruitsUser);
    }, [user.orgId]);

    return (
        <>
            {import.meta.env.VITE_PRODUCT_FRUITS_WORKSPACE_CODE &&
                org.id !== SYSTEM_ORG_ID.SYSTEM_CONSOLE && (
                    <ProductFruits
                        workspaceCode={import.meta.env.VITE_PRODUCT_FRUITS_WORKSPACE_CODE!}
                        language="en"
                        user={productFruitsUser}
                    />
                )}
        </>
    );
});
