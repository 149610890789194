import React, { useEffect } from 'react';
import logo from '../../resources/images/logo.svg';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { navRoutes } from 'shared/navigation/navRoutes';
import { observer } from 'mobx-react-lite';
import { useAuthStore } from '@/stores/AuthStore';

export const LandingPage = observer(() => {
    const navigate = useNavigate();
    const authStore = useAuthStore();

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const reason = searchParams.get('reason');
        if (reason === 'logout') {
            navigate(navRoutes.public_login.path);
        }
    }, []);

    return (
        <div className="relative flex min-h-screen w-screen flex-col justify-start bg-gray-100 md:justify-center">
            <div>
                {!authStore.isLoginInProgress && (
                    <Button
                        type="primary"
                        size="large"
                        className="absolute right-6 top-4"
                        onClick={() => {
                            navigate(navRoutes.public_login.path);
                        }}
                    >
                        Log in
                    </Button>
                )}

                <img
                    src={logo}
                    alt="Logo"
                    className="w-300px absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
                />
            </div>
        </div>
    );
});
