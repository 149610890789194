import { logInDev } from '@/utils/general';
import * as Sentry from '@sentry/react';
import { message } from 'antd';
import { LDSingleKindContext } from 'launchdarkly-react-client-sdk';
import LogRocket from 'logrocket';
import { SYSTEM_ORG_ID } from 'shared/constants/org';
import { Feature, navRoutes } from 'shared/navigation/navRoutes';
import { UserFlagEnum } from 'shared/types/global';
import { decodeJwtToken, JwtClaims } from 'shared/utils/jwt';
import { create } from 'zustand';
import type { AuthStore, AuthStoreOrg, AuthStoreUser } from './types';

export const useAuthStore = create<AuthStore>((set, get) => ({
    user: undefined,
    org: undefined,
    isOrgChangeInProgress: false,
    isLoginInProgress: false,
    isLogoutInProgress: false,
    authTokenExpired: false,

    setLoggingInProgress: (value) => set({ isLoginInProgress: value }),

    setApolloClient: (apolloClient) => set({ apolloClient: apolloClient }),

    setTrpcClient: (trpcClient) => set({ trpcClient: trpcClient }),

    setWsLink: (wsLink) => set({ wsLink: wsLink }),

    setLDClient: (ldClient) => set({ ldClient: ldClient }),

    login: async (accessToken) => {
        try {
            const { setAuthToken, loadUser, loadOrg, apolloClient, initUserServices } = get();
            const claims = decodeJwtToken(accessToken);
            if (!claims) {
                throw new Error('AuthStore: Claims are undefined.');
            }
            set({ isLoginInProgress: true });
            setAuthToken(accessToken);
            if (apolloClient) {
                apolloClient.resetStore();
            }
            const user = await loadUser(claims);
            const org = await loadOrg(claims);
            set({ user: user, org: org });
            await initUserServices();
            return user;
        } finally {
            set({ isLoginInProgress: false });
        }
    },

    changeOrg: async (newAccessToken) => {
        try {
            const { apolloClient, setAuthToken, initUserServices } = get();
            if (!apolloClient) {
                throw new Error('AuthStore: Apollo client is undefined.');
            }

            const claims = decodeJwtToken(newAccessToken);
            if (!claims) {
                throw new Error('AuthStore: Claims are undefined.');
            }
            set({ isOrgChangeInProgress: true });
            setAuthToken(newAccessToken);
            apolloClient.resetStore();
            const user = await get().loadUser(claims);
            const org = await get().loadOrg(claims);
            set({ user: user, org: org });
            await initUserServices();
        } finally {
            set({ isOrgChangeInProgress: false });
        }
    },

    setAuthToken: (token) => {
        const { wsLink, setAuthTokenExpired } = get();
        if (!wsLink) {
            throw new Error('AuthStore: WebSocket link is undefined.');
        }
        logInDev('Setting auth token');
        setAuthTokenExpired(false);
        set({ accessToken: token });
        const tokenExpiration = decodeJwtToken(token)?.exp;
        set({
            authTokenExpiration: tokenExpiration ? new Date(tokenExpiration * 1000) : undefined,
        });
        wsLink.client.terminate();
    },

    getAuthToken: () => get().accessToken,

    getAuthTokenExpiration: () => {
        const user = get().user;
        return user?.data.exp ? new Date(user.data.exp * 1000) : undefined;
    },

    setAuthTokenExpired: (value) => set({ authTokenExpired: value }),

    setUser: (user) => set({ user: user }),

    logout: (auth0LogoutFunction) => {
        try {
            set({ isLogoutInProgress: true });
            void message.info('Logging out...', 5);
            try {
                // @ts-expect-error This is a ProductFruits type bug
                window.productFruits.services.destroy();
            } catch (e) {
                console.log('Error destroying product fruits', e);
            }
            auth0LogoutFunction({
                logoutParams: { returnTo: window.location.origin + navRoutes.public_logOutIn.path },
            });
            Sentry.setUser(null);
        } finally {
            setTimeout(() => {
                set({ isLogoutInProgress: false });
            }, 1000);
            message.destroy();
        }
    },

    // Additional methods and computed properties
    getIsAuthenticated: () => {
        const { user } = get();
        return !!user;
    },

    loadUser: async (claims: JwtClaims) => {
        const { trpcClient } = get();
        if (!trpcClient) {
            throw new Error('AuthStore: TRPC client is undefined.');
        }

        const [dbUser, orgs] = await Promise.all([
            trpcClient.user.getUser.query({
                id: BigInt(claims['https://curium.app/claims'].userId),
                orgId: BigInt(claims['https://curium.app/claims'].orgId),
                select: ['handlingPartyIds'],
            }),
            trpcClient.user.getMyOrgs.query(),
        ]);

        const flagConditions = [
            { condition: dbUser?.isPaymentAdmin, flag: UserFlagEnum.paymentAdmin },
            {
                condition: dbUser.canViewUnallocatedClaims,
                flag: UserFlagEnum.canViewUnallocatedClaims,
            },
        ];

        const user: AuthStoreUser = {
            data: claims,
            termsAccepted: dbUser.termsAccepted === true,
            name: dbUser.name || '',
            firstName: dbUser.firstName || '',
            lastName: dbUser.lastName || '',
            photoUrl: dbUser.photoUrl || '',
            signedUpAt: dbUser.createdAt,
            externalOrgIds: dbUser.handlingPartyIds?.map((el) => Number(el)) || [],
            isEmployee: dbUser.isEmployee || false,
            isSupportAccount: dbUser.isSupportAccount || false,
            canViewUnallocatedClaims: dbUser.canViewUnallocatedClaims || false,
            canCreatePayment: false,
            canApprovePayment: false,
            orgId: Number(dbUser.orgId),
            id: Number(dbUser.userId),
            auth0Id: dbUser.auth0Id,
            email: dbUser.email,
            isSuperAdmin: dbUser.orgId === BigInt(SYSTEM_ORG_ID.SYSTEM_CONSOLE),
            roles: dbUser.roles,
            fullName: dbUser.fullName,
            isOrgAdmin: dbUser.roles.includes('org_admin'),
            flags: flagConditions.filter(({ condition }) => condition).map(({ flag }) => flag),
            userOrgs: orgs.map((el) => ({ id: el.id, name: el.name })),
            isPrivilegedSuperAdmin: dbUser.isPrivilegedSuperAdmin,
        };
        return user;
    },

    loadOrg: async (claims: JwtClaims) => {
        const { trpcClient } = get();
        if (!trpcClient) {
            throw new Error('AuthStore: TRPC client is undefined.');
        }
        if (claims['https://curium.app/claims'].orgId === SYSTEM_ORG_ID.SYSTEM_CONSOLE) {
            return {
                id: SYSTEM_ORG_ID.SYSTEM_CONSOLE,
                name: '',
                features: [],
                isClaimFeatureEnabled: false,
                isComplianceFeatureEnabled: false,
                isRiskFeatureEnabled: false,
                key: '',
                enabledOrgModules: [],
            } satisfies AuthStoreOrg;
        }
        const org = await trpcClient.org.getOrg.query({
            orgId: BigInt(claims['https://curium.app/claims'].orgId),
        });

        if (!org) {
            throw new Error("Can't load org details.");
        }
        const features: Feature[] = [];
        if (org.isClaimsFeatureEnabled) features.push('claim');
        if (org.isComplianceFeatureEnabled) features.push('compliance');
        if (org.isRiskFeatureEnabled) features.push('risk');
        return {
            id: Number(org.id),
            name: org.name,
            isClaimFeatureEnabled: org.isClaimsFeatureEnabled || false,
            isComplianceFeatureEnabled: org.isComplianceFeatureEnabled || false,
            isRiskFeatureEnabled: org.isRiskFeatureEnabled || false,
            features: features,
            key: org.key,
            enabledOrgModules: org.enabledModules,
        } satisfies AuthStoreOrg;
    },

    initUserServices: async (initUserServices = true) => {
        const { trpcClient, ldClient, user, org } = get();
        if (!trpcClient) {
            throw new Error('AuthStore: TRPC client is undefined.');
        }
        if (!user) {
            throw new Error('AuthStore: User is undefined.');
        }
        if (!org) {
            throw new Error('AuthStore: Org is undefined.');
        }

        if (ldClient) {
            const ldUser: LDSingleKindContext = {
                kind: 'user',
                name: user.fullName || '',
                key: user.email || '',
                orgId: org.id || '',
                orgName: org.name || '',
                roles: user.roles,
                type: /Android|iPhone|iPad/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
                browserAgent: navigator.userAgent,
                screenResolution: `${window.screen.width}x${window.screen.height}`,
            };
            await ldClient.identify(ldUser);
        }
        if (import.meta.env.VITE_ENV === 'production' || import.meta.env.VITE_ENV === 'staging') {
            Sentry.setUser({
                id: user.id.toString(),
                name: user.fullName || '',
                email: user.email || '',
                org: org.name || '',
                orgId: org.id || '',
                roles: user.roles,
            });
            Sentry.setTags({
                isUser: true,
                isOrgAdmin: user.isOrgAdmin,
                isSuperAdmin: user.isSuperAdmin,
            });
            if (initUserServices) {
                LogRocket.identify(user.id.toString(), {
                    name: user.fullName || '',
                    email: user.email || '',
                    org: org.name || '',
                    orgId: org.id || '',
                });
            }
        }
    },

    reloadUser: async (accessToken?: string) => {
        const { setAuthToken, loadUser, setUser, initUserServices } = get();
        const token = accessToken || get().accessToken;
        if (!token) {
            throw new Error('AuthStore: Token is undefined.');
        }
        const claims = decodeJwtToken(token);
        if (!claims) {
            throw new Error('AuthStore: Claims are undefined.');
        }
        if (accessToken) {
            setAuthToken(accessToken);
        }
        const user = await loadUser(claims);
        setUser(user);
        await initUserServices();
    },
}));

// Selector functions
export const selectUser = (state: AuthStore) => state.user;
export const selectOrg = (state: AuthStore) => state.org;
