/*
We can't logout and go to the login page because Auth0 doesn't allow us to do that. This is workaround.
*/
import React, { useEffect } from 'react';
import logo from '../../resources/images/logo.svg';
import { useNavigate } from 'react-router-dom';
import { navRoutes } from 'shared/navigation/navRoutes';
import { observer } from 'mobx-react-lite';

export const LogOutIn = observer(() => {
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate(navRoutes.public_login.path);
        }, 2500);
    }, []);

    return (
        <div className="relative flex min-h-screen w-screen flex-col justify-start bg-gray-100 md:justify-center">
            <div>
                <img
                    src={logo}
                    alt="Logo"
                    className="w-300px absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
                />
                <div className="absolute left-1/2 top-1/2 mt-20 -translate-x-1/2 -translate-y-1/2 transform text-lg text-gray-600">
                    Redirecting to login page...
                </div>
            </div>
        </div>
    );
});
